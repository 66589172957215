<template>
  <v-col cols="12" class="col-lg-4 form-detail-section">
    <div class="quote-md-grid">
      <v-card elevation="2" class="mb-6" v-if="scanLoading">
        <v-skeleton-loader :loading="true" elevation="2" type="card-heading, image"></v-skeleton-loader>
      </v-card>
      <v-card class="mb-5" v-else>
        <v-toolbar :color="color" :style="`color: ${textColor}`" class="mb-5 scan-title-bar" elevation="3">
          <v-toolbar-title class="text-section-title"> Car Details</v-toolbar-title>
        </v-toolbar>
        <v-row class="ma-0 pb-3">
          <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Registration:
          </v-col>
          <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8">
            <v-row v-if="licensePlateEdit" class="ma-0">
              <v-combobox class="w-60" outlined dense hide-details="auto"
                          :value="model.licensePlate"
                          :items="licenseNoLists"
                          :loading="isLoading"
                          :search-input.sync="regSearch"
                          hide-no-data
                          label=""
                          item-text="number"
                          item-value="number"
                          placeholder="Registration"
                          @change="updateLicenseNo"
                          return-object
              ></v-combobox>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="updateScanDetails('licensePlate', 'licensePlateEdit', 'regSearch')">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-content-save</v-icon>
              </div>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="licensePlateEdit = false;selectLicense = {}">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-window-close</v-icon>
              </div>
            </v-row>
            <v-row v-if="licensePlateEdit && regSearch"
                   no-gutters align="center" class="mt-1">
              <span class="ml-3">sync:</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :color="color" text small icon class="ml-2" :disabled="fetchLoading || isLoading"
                         @click="fetchLicensePlate" v-bind="attrs" v-on="on">
                    <v-icon :size="25" :class="{'icon-loader': fetchLoading}">mdi-sync-circle</v-icon>
                  </v-btn>
                </template>
                <span>Fetch Registration</span>
              </v-tooltip>
            </v-row>
            <v-row v-if="!licensePlateEdit" class="ma-0 text-uppercase">
              {{ model.licensePlate || 'Nil' }}
              <v-icon size="15" :color="color" class="pa-0 ma-0 ml-2 cursor-pointer"
                      @click="licensePlateEdit=true;">
                mdi-pencil
              </v-icon>
            </v-row>
          </v-col>
          <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Make:
          </v-col>
          <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8">
            <v-row v-if="makeEdit" class="ma-0">
              <v-text-field v-model="model.make" outlined dense hide-details="auto"
                            class="w-60" placeholder="Make">
              </v-text-field>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="updateScanDetails('make', 'makeEdit')">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-content-save</v-icon>
              </div>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="makeEdit = false;model.make = tempModel.make">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-window-close</v-icon>
              </div>
            </v-row>
            <v-row v-if="!makeEdit" class="ma-0">
              {{ model.make || 'Nil' }}
              <v-icon size="15" :color="color" class="pa-0 ma-0 ml-2 cursor-pointer"
                      @click="makeEdit=true;">
                mdi-pencil
              </v-icon>
            </v-row>
          </v-col>
          <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Model:
          </v-col>
          <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8 ">
            <v-row v-if="modelEdit" class="ma-0">
              <v-text-field v-model="model.model" outlined dense hide-details="auto"
                            class="w-60" placeholder="Model">
              </v-text-field>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="updateScanDetails('model', 'modelEdit')">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-content-save</v-icon>
              </div>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="modelEdit = false;model.model = tempModel.model">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-window-close</v-icon>
              </div>
            </v-row>
            <v-row v-if="!modelEdit" class="ma-0">
              {{ model.model || 'Nil' }}
              <v-icon size="15" :color="color" class="pa-0 ma-0 ml-2 cursor-pointer"
                      @click="modelEdit=true;">
                mdi-pencil
              </v-icon>
            </v-row>
          </v-col>
          <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Colour:
          </v-col>
          <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8 text-capitalize">
            <v-row v-if="carColorEdit" class="ma-0">
              <v-text-field v-model="model.carColor" outlined dense hide-details="auto"
                            class="w-60" placeholder="Colour">
              </v-text-field>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="updateScanDetails('carColor', 'carColorEdit')">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-content-save</v-icon>
              </div>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="carColorEdit = false;model.carColor = tempModel.carColor">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-window-close</v-icon>
              </div>
            </v-row>
            <v-row v-if="!carColorEdit" class="ma-0">
              {{ model.carColor || 'Nil' }}
              <v-icon size="15" :color="color" class="pa-0 ma-0 ml-2 cursor-pointer"
                      @click="carColorEdit=true;">
                mdi-pencil
              </v-icon>
            </v-row>
          </v-col>
          <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Body Type:
          </v-col>
          <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8 text-capitalize">
            <v-row v-if="carBodyTypeEdit" class="ma-0">
              <v-text-field v-model="model.body_type" outlined dense hide-details="auto"
                            class="w-60" placeholder="Body Type">
              </v-text-field>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="updateScanDetails('body_type', 'carBodyTypeEdit')">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-content-save</v-icon>
              </div>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="carBodyTypeEdit = false;model.body_type = tempModel.body_type">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-window-close</v-icon>
              </div>
            </v-row>
            <v-row v-if="!carBodyTypeEdit" class="ma-0">
              {{ model.body_type || 'Nil' }}
              <v-icon size="15" :color="color" class="pa-0 ma-0 ml-2 cursor-pointer"
                      @click="carBodyTypeEdit=true;">
                mdi-pencil
              </v-icon>
            </v-row>
          </v-col>
          <v-col v-if="!agentId" cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Valuation:
          </v-col>
          <v-col v-if="!agentId" cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8 text-capitalize">
            <v-row v-if="carValuationEdit" class="ma-0">
              <v-text-field v-model="model.valuation" outlined dense hide-details="auto" v-currency
                            class="w-60" placeholder="Valuation" prepend-inner-icon="mdi-currency-usd">
              </v-text-field>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="updateScanDetails('valuation', 'carValuationEdit')">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-content-save</v-icon>
              </div>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="carValuationEdit = false;model.valuation = tempModel.valuation">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-window-close</v-icon>
              </div>
            </v-row>
            <v-row v-if="!carValuationEdit" class="ma-0">
              $ {{ model.valuation || 'Nil' }}
              <v-icon size="15" :color="color" class="pa-0 ma-0 ml-2 cursor-pointer"
                      @click="carValuationEdit=true;">
                mdi-pencil
              </v-icon>
            </v-row>
          </v-col>
          <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Location:
          </v-col>
          <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8 text-capitalize">
            <v-row v-if="locationEdit" class="ma-0">
              <v-select v-model="model.location_state" outlined dense hide-details="auto" :items="stateList"
                        :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                        class="text-capitalize w-60" placeholder="----Code - State Name - Country Code----"></v-select>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="updateScanDetails('location_state', 'locationEdit')">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-content-save</v-icon>
              </div>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="locationEdit = false;model.location_state = tempModel.location_state">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-window-close</v-icon>
              </div>
            </v-row>
            <v-row v-if="!locationEdit" class="ma-0">
              {{ model.location_state || 'Nil' }}
              <v-icon size="15" :color="color" class="pa-0 ma-0 ml-2 cursor-pointer"
                      @click="locationEdit=true;">
                mdi-pencil
              </v-icon>
            </v-row>
          </v-col>
          <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Client:
          </v-col>
          <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8 text-capitalize">
            <v-row v-if="clientEdit" class="ma-0">
              <v-select v-model="model.client_id" outlined dense hide-details="auto" :items="clientsList"
                        :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                        class="text-capitalize w-60" placeholder="Client"></v-select>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="clientEdit = true;updateClient()">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-content-save</v-icon>
              </div>
              <div class="cursor-pointer ml-2 d-flex align-center justify-center flex-column"
                   @click="clientEdit = false;model.client_id = tempModel.client_id">
                <v-icon size="25" :color="color" class="pa-0 ma-0">mdi-window-close</v-icon>
              </div>
            </v-row>
            <v-row v-if="!clientEdit" class="ma-0">
              {{ model.client_name || 'Nil' }}
              <v-icon size="15" :color="color" class="pa-0 ma-0 ml-2 cursor-pointer"
                      @click="clientEdit=true;">
                mdi-pencil
              </v-icon>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card elevation="2" class="mb-6" v-if="scanLoading">
        <v-skeleton-loader :loading="true" elevation="2" type="card-heading, image"></v-skeleton-loader>
      </v-card>
      <v-card class="mb-5" v-else>
        <v-toolbar :color="color" :style="`color: ${textColor}`" class="mb-5 scan-title-bar" elevation="3">
          <v-toolbar-title class="text-section-title"> Scan Details</v-toolbar-title>
        </v-toolbar>
        <v-row class="ma-0 pb-3">
          <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Type:
          </v-col>
          <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8 text-capitalize">
            {{ data && data.type ? data.type : '-' }}
          </v-col>
          <v-col ccols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Date/Time:
          </v-col>
          <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8 text-capitalize">
            {{ data && data.startTime ? $helpers.getMomentDatas('DD-MM-YYYY hh:mm A', data.startTime) : '-' }}
          </v-col>
          <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Damage Detected:
          </v-col>
          <v-col cols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8">
            {{ data && data.damageCount ? 'Yes' : 'No' }}
          </v-col>
          <v-col cols="12" class="header-label col-sm-4 col-md-4 col-xl-4 col-lg-4 font-weight-bold text-break">
            Damage Count:
          </v-col>
          <v-col ccols="12" class="content-detail col-sm-8 col-md-8 col-xl-8 col-lg-8">
            {{ data && data.damageCount ? data.damageCount : 0 }}
          </v-col>
        </v-row>
      </v-card>
      <template v-if="tab === 'arch'">
        <v-card elevation="2" class="mb-6" v-if="scanLoading || metaArchLoading || dataLoading">
          <v-skeleton-loader :loading="true" elevation="2" type="card-heading, image"></v-skeleton-loader>
        </v-card>
        <v-card class="mb-5" v-else>
          <v-toolbar :color="color" dark class="mb-5 scan-title-bar" elevation="3">
            <v-toolbar-title class="text-section-title"> Total Dents & Scratchs</v-toolbar-title>
          </v-toolbar>
          <v-row class="ma-0 pb-3">
            <v-col cols="12" xl="6" lg="6" md="6" class="py-0 px-2">
              <v-list shaped class="pa-0">
                <div class="d-flex align-center justify-space-between mr-2 ml-2 my-2">
                  <div dense v-text="'Dents'" class="mr-1 font-weight-bold"></div>
                  <div dense v-text="overallTotals.dents" class="font-weight-bold"></div>
                </div>
                <v-list-item-group dense>
                  <v-list-item v-for="(item, i) in overallTotals.lists" :key="i" dense class="pr-0 pl-2">
                    <v-list-item-icon dense class="mr-0">
                      <v-icon :color="item.color" size="15" v-text="item.icon" class="ma-0 pa-0"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content dense>
                      <div class="d-flex align-center justify-space-between">
                        <div dense v-text="item.text" class="mr-2"></div>
                        <div dense v-text="item.dent"></div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" class="py-0 px-2 pr-4">
              <v-divider class="d-block d-md-none d-xl-none d-lg-none"/>
              <v-list shaped class="pa-0">
                <div class="d-flex align-center justify-space-between mr-2 ml-2 my-2">
                  <div dense v-text="'Scratchs'" class="mr-1 font-weight-bold"></div>
                  <div dense v-text="overallTotals.scratchs" class="font-weight-bold"></div>
                </div>
                <v-list-item-group dense>
                  <v-list-item v-for="(item, i) in overallTotals.lists" :key="i" dense class="pr-0 pl-2">
                    <v-list-item-icon dense class="mr-0 d-none d-lg-block">
                      <v-icon :color="item.color" size="15" v-text="item.icon" class="ma-0 pa-0"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content dense>
                      <div class="d-flex align-center justify-space-between">
                        <div dense v-text="item.text" class="mr-2"></div>
                        <div dense v-text="item.scratch"></div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </template>
      <Report :color="color" :pdfs="data.report_pdfs" v-if="data.report_pdfs" :selectOpt="selectOpt" @pdfSelect="$emit('pdfSelection', $event)"/>
    </div>
  </v-col>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import _ from 'lodash';
import {Currency} from '../../../directives';

export default {
  props: ['data', 'damageLists', 'selectOpt', 'pdfSelection', 'tab', 'color', 'textColor', 'agentId'],
  directives: {
    'currency': Currency,
  },
  components: {
    Report: () => import("./Report"),
  },
  data: (vm) => ({
    scanId: vm.$route.params.scanId,
    dataLoading: false,
    overallTotals: {
      dents: 0,
      scratchs: 0,
      lists: [
        { text: 'Oversize', icon: 'mdi-checkbox-blank', color: '#561329', dent: 0, scratch: 0 },
        { text: 'Severe', icon: 'mdi-checkbox-blank', color: '#81182E', dent: 0, scratch: 0 },
        { text: 'Heavy', icon: 'mdi-checkbox-blank', color: '#C1484E', dent: 0, scratch: 0 },
        { text: 'Medium', icon: 'mdi-checkbox-blank', color: '#CC9188', dent: 0, scratch: 0 },
        { text: 'Light', icon: 'mdi-checkbox-blank', color: '#D8D1C1', dent: 0, scratch: 0 },
      ]
    },
    model: {
      client_id: '',
      licensePlate: '',
      carColor: '',
      model: '',
      make: '',
      body_type: '',
      valuation: 0,
    },
    scanEdited: false,
    licensePlateEdit: false,
    makeEdit: false,
    modelEdit: false,
    carColorEdit: false,
    carBodyTypeEdit: false,
    carValuationEdit: false,
    clientEdit: false,
    licenseNoLists: [],
    isLoading: false,
    regSearch: '',
    selectLicense: {},
    fetchLoading: false,
    tempModel: {},
    locationEdit: false,
    stateList: [
      { text: 'ACT - Australian Capital Territory - AUS', value: 'ACT' }, { text: 'NSW - New South Wales - AUS', value: 'NSW' },
      { text: 'NT - Northern Territory - AUS', value: 'NT' }, { text: 'QLD - Queensland - AUS', value: 'QLD' },
      { text: 'SA - South Australia - AUS', value: 'SA' }, { text: 'TAS - Tasmania - AUS', value: 'TAS' },
      { text: 'VIC - Victoria - VIC', value: 'ACT' }, { text: 'WA - Western Australia - AUS', value: 'WA' }
    ]
  }),
  computed: {
    ...mapGetters(['scanLoading', 'metaArchLoading', 'clientsList'])
  },
  watch: {
    damageLists(newValue) {
      if (newValue) this.getOverallTotal(newValue);
    },
    'data.client_id': function (newValue) {
      if (newValue) {
        this.model.client_id = newValue;
        this.model.client_name = this.data.client_name;
      }
    },
    'data.licensePlate': function (newValue) {
      if (newValue) this.model.licensePlate = newValue;
    },
    'data.carColor': function (newValue) {
      if (newValue) this.model.carColor = newValue;
    },
    'data.model': function (newValue) {
      if (newValue) this.model.model = newValue;
    },
    'data.make': function (newValue) {
      if (newValue) this.model.make = newValue;
    },
    'data.body_type': function (newValue) {
      if (newValue) this.model.body_type = newValue;
    },
    'data.valuation': function (newValue) {
      if (newValue) this.model.valuation = newValue;
    },
    'data.location_state': function (newValue) {
      if (newValue) this.model.location_state = newValue;
    },
    regSearch(val) {
      if (val) this.searchLicensePlate(val);
    },
    'model.valuation': function (newValue) {
      if (newValue) {
        const result = newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.$vm.nextTick(() => this.model.valuation = result);
      }
    }
  },
  created() {
    this.searchLicensePlate = _.debounce(this.searchLicensePlate, 500);
    if (this.data) {
      this.model.client_id = this.data.client_id;
      this.model.client_name = this.data.client_name;
      this.model.licensePlate = this.data.licensePlate;
      this.model.carColor = this.data.carColor;
      this.model.body_type = this.data.body_type;
      this.model.valuation = this.data.valuation;
      this.model.model = this.data.model;
      this.model.make = this.data.make;
      this.model.location_state = this.data.location_state;
    }
    this.getClientLists();
    this.tempModel = _.clone(this.model);
  },
  mounted() {
    this.wsScanData();
  },
  methods: {
    ...mapActions(['updateSelectedScan', 'getClientLists', 'getLicenseAutoComplete', 'fetchLicense']),

    async getOverallTotal(value) {
      this.dataLoading = true;
      this.overallTotals.dents = Object.keys(value).reduce((sum, key) => sum + parseFloat(value[key].dent_total || 0), 0);
      this.overallTotals.scratchs = Object.keys(value).reduce((sum, key) => sum + parseFloat(value[key].scratch_total || 0), 0);
      await this.overallTotals.lists.map(async (item, index) => {
        item.dent = await Object.keys(value).reduce((sum, key) => sum + parseFloat(value[key].tooltip[index].dent || 0), 0);
        item.scratch = await Object.keys(value).reduce((sum, key) => sum + parseFloat(value[key].tooltip[index].scratch || 0), 0);
      });
      setTimeout(() => this.dataLoading = false);
    },
    async updateClient() {
      const reqData = { id: this.scanId, client_id: this.model.client_id, noLoading: true };
      this.clientEdit = false;
      const CLIENT = this.clientsList.find(item => item.value == this.model.client_id);
      this.model.client_name = reqData['client_name'] = CLIENT.text;
      this.updateSelectedScan(reqData).catch(err => {
        this.model.client_id = this.data.client_id;
        this.model.client_name = this.data.client_name;
        this.tempModel = _.clone(this.model);
      });
    },
    updateScanDetails(key, field, valueField = null) {
      this.scanEdited = true;
      this[field] = false;
      let reqData = { id: this.scanId };
      if (valueField) {
        reqData = {
          ...reqData,
          ...this.selectLicense,
          licensePlate: this.regSearch
        }
        this.model = { ...this.model, ...this.selectLicense };
        this.model.licensePlate = this.regSearch;
      } else {
        reqData[key] = this.model[key];
      }
      reqData['noLoading'] = true;
      this.updateSelectedScan(reqData).then(res => {
        this.tempModel = _.clone(this.model);
      }).catch(err => this.model[key] = this.data[key]);
    },
    wsScanData() {
      const self = this;
      Echo.channel('scan').listen('.updated', (scan) => {
        if (scan.status == 'uploaded' && self.scanId == scan.id && !self.scanEdited) {
          self.scanEdited = false;
          self.model.type = scan.type;
          self.model.carColor = scan.carColor;
          self.model.licensePlate = scan.licensePlate;
          self.model.make = scan.make;
          self.model.model = scan.model;
          self.model.client_name = scan.client_name;
          self.model.client_id = scan.client_id;
          self.model.body_type = scan.body_type;
          self.model.valuation = scan.valuation;
          self.model.location_state = scan.location_state;
          self.tempModel = _.clone(self.model);
        }
      });
    },
    updateLicenseNo(value) {
      if (value && typeof value === 'object') {
        const { valuation_response, ag_response, number } = value || {};
        const { vehicle } = ag_response;
        this.selectLicense = {
          licensePlate: number,
          carColor: ag_response.colour,
          body_type: vehicle.body_type,
          valuation: valuation_response.price,
          model: vehicle.model,
          make: vehicle.make
        }
      }
    },
    searchLicensePlate(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true

      // Lazily load input items
      this.getLicenseAutoComplete({ number: val })
        .then(res => {
          this.licenseNoLists = res || [];
        }).catch(err => this.isLoading = false).finally(() => this.isLoading = false);
    },
    fetchLicensePlate() {
      this.fetchLoading = true;
      this.fetchLicense({ number: this.regSearch || this.model.licensePlate, state: this.model.location_state }).then(res => {
        if (res) {
          this.licenseNoLists = [];
          this.licenseNoLists.push(res)
        }
        this.fetchLoading = false;
      })
        .catch(err => this.fetchLoading = false).finally(() => this.fetchLoading = false);
    }
  },
  beforeDestroy() {
    Echo.leaveChannel('scan');
  }
}
</script>

<style>

</style>